import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EnumNewsType, EnumViewCounterFrequency, INewsDetails, INewsListItem, INextNewNews } from '../../shared/models/news.model';
import { ISuppliers } from '../../shared/models/supplier-search/supplier-result.model';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private from: any = 1;
  private totalNewsCount: any = 10;
  private selecteCategory: any = 1;

  constructor(private httpClient: HttpClient) {}

  getNewsDetailFromACS(id: string): Observable<INewsDetails> {
    return this.httpClient.get<INewsDetails>(environment.api + `/news/${id}`);
  }

  getNewsDetailFromDB(id: string): Observable<INewsDetails> {
    return this.httpClient.get<INewsDetails>(environment.api + `/news/FriendlyId/news-preview/${id}`);
  }

  getNewsListByCategory(categoryId: EnumNewsType, page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/list/${categoryId}?page=${page}&size=${pageSize}`);
  }

  getBigNewsList(page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/list/big?page=${page}&size=${pageSize}`);
  }

  getLatestNewsList(page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/list/latest?page=${page}&size=${pageSize}`);
  }

  getNativeNewsList(page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/list/native?page=${page}&size=${pageSize}`);
  }

  getTopNewsList(frequency: EnumViewCounterFrequency, page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/list/top/${frequency}?page=${page}&size=${pageSize}`);
  }

  getNextNewsList(officeId: string, categoryId: EnumNewsType, id: string, page = 1, pageSize = 10): Observable<INewsListItem[]> {
    return this.httpClient.get<INewsListItem[]>(environment.api + `/news/next/${categoryId}/${officeId}/${id}?page=${page}&size=${pageSize}`);
  }

  getRedirectUrl(path: string): Observable<any> {
    return this.httpClient.get<any>(environment.api + `/news/redirecturl/${path}`);
  }
  getCatPathFromNews(news: INewsListItem | INewsDetails | INextNewNews) {
    let categoryId = EnumNewsType.TopNews;
    if (news.newsCategories && news.newsCategories.length > 0) {
      const primaryCategory = news.newsCategories.find(x => x.weight === 0);
      let category = primaryCategory ?? news.newsCategories[0];
      categoryId = parseInt(category.masterCategoryId);
    }
    let catPath = this.getMainCategoryRoute(categoryId);
    return catPath;
  }

  getSupplierByOfficeId(officeId: string): Observable<ISuppliers> {
    return this.httpClient.get<ISuppliers>(`${environment.api}/suppliersearch/searchByOfficeId/${officeId}`);
  }

  getRandomLatestNews(listIds: string[]): Observable<any> {
    return this.httpClient.post<INewsListItem[]>(environment.api + `/news/getRandomLatestNews`, listIds);
  }

  getMainCategoyEnum(text: string): EnumNewsType {
    switch (text.toLowerCase()) {
      case 'hem':
        return EnumNewsType.Home;
      case 'nyheter':
        return EnumNewsType.TopNews;
      case 'ekonomi-och-juridik':
        return EnumNewsType.Economy;
      case 'produktnyheter':
        return EnumNewsType.ProductNews;
      case 'reportage':
        return EnumNewsType.Reportage;
      case 'kundnyheter':
        return EnumNewsType.NewsDirect;
      case 'deliverynews':
        return EnumNewsType.DeliveryNews;
      case 'lagar-och-regler':
        return EnumNewsType.LawsAndRules;
      case 'samarbetspartners':
        return EnumNewsType.Partners;
      case 'play':
        return EnumNewsType.Play;
      case 'technic':
        return EnumNewsType.Technic;
      case 'environment':
        return EnumNewsType.Environment;
      case 'business':
        return EnumNewsType.Business;
      case 'work':
        return EnumNewsType.Work;
    }

    return null;
  }

  getMainCategoryRoute(categoryId: number): string {
    switch (categoryId) {
      case EnumNewsType.Home:
        return 'hem';
      case EnumNewsType.TopNews:
        return 'nyheter';
      case EnumNewsType.Economy:
        return 'ekonomi-och-juridik';
      case EnumNewsType.ProductNews:
        return 'produktnyheter';
      case EnumNewsType.Reportage:
        return 'reportage';
      case EnumNewsType.NewsDirect:
        return 'kundnyheter';
      case EnumNewsType.Partners:
        return 'samarbetspartners';
      case EnumNewsType.Play:
        return 'play';
      case EnumNewsType.LawsAndRules:
        return 'lagar-och-regler';
    }

    return 'nyheter';
  }

  getFromPosition() {
    return this.from;
  }

  setFromPosition(pos: any) {
    this.from = pos;
  }

  getTotalNewsCount() {
    return this.totalNewsCount;
  }

  setTotatlNewsCount(totalNewsCount: any) {
    this.totalNewsCount = totalNewsCount;
  }

  getSelectedCategory() {
    return this.selecteCategory;
  }

  resetQueryParams(category: any) {
    this.from = 1;
    this.totalNewsCount = 10;
    this.selecteCategory = category;
  }
}
