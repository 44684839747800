import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewsRedirectComponent } from './news/components/redirect/redirect.component';
import { SearchNewsByTagNameComponent } from './news/components/search-tag-name/search-tag-name.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
  //{ path: 'leverantor', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'tags', children: [{ path: ':tagname', component: SearchNewsByTagNameComponent }] },
  { path: 'Tags', children: [{ path: ':tagname', component: SearchNewsByTagNameComponent }] },
  { path: 'las-brf-mappen', loadChildren: () => import('./advertiser/advertiser.module').then(m => m.AdvertiserModule) },
  { path: 'om-oss', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  //{ path: 'suppliers', loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule) },
  { path: 'hitta-leverantor', loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierModule) },
  {
    path: 'flipbook',
    loadChildren: () =>
      import('./flipbook/flipbook.module').then((m) => m.FlipbookModule)
  },  
  { path: '**', component: NewsRedirectComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollOffset: [0, 0],
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
