<header class="main-header">
  <nav class="navbar top">
    <div class="container" *ngIf="shouldShowHeader">
      <div id="secondary-menu" class="bg-grey-lighter d-md-none">
        <div class="mobile-top-container">
          <ul id="menu-toppmeny" class="p-0 navbar-nav d-flex flex-row w-100 justify-content-center justify-md-content-end">
            <li>
              <a class="mobile-link" href="https://fastighetsmedia.se/annonsera" target="_blank">Annonsera</a>
            </li>

            <li>
              <a class="mobile-link" routerLink="/om-oss">Om oss</a>
            </li>
            <li>
              <a class="mobile-link" href="https://fastighetsmedia.se/kontakt" target="_blank">Kontakt</a>
            </li>
            <li>
              <a class="mobile-link" href="{{ loginUrl }}" target="_blank">Logga in</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row header-wrapper">
        <div class="col-sm-12 col-md-6 col-lg-6 text-center text-md-left d-flex align-items-center img-logo justify-content-center justify-content-md-start">
          <a class="site-logo" routerLink="/" (click)="checkReloadPage()">
            <img class="img-responsive main-logo" src="https://cdn.brfmappen.se/public/images/logo/Logo-brfmappen-desktop2.svg" alt="Logo" *ngIf="!mobileView" />

            <img class="img-responsive main-logo mobile-logo-img" src="https://cdn.brfmappen.se/public/images/logo/Logo-brfmappen-mobile2.svg" alt="Logo" *ngIf="mobileView" />
          </a>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-6 search-links d-md-block d-none">
          <div class="top-menu">
            <nav class="navbar navbar-toggleable-md pr-0">
              <div class="navbar-ex1-collapse w-100">
                <ul id="menu-toppmeny" class="p-0 navbar-nav d-flex flex-row w-100 justify-content-end">
                  <li>
                    <a class="mobile-link" href="https://fastighetsmedia.se/annonsera" target="_blank">Annonsera</a>
                  </li>
                  <!-- <li>
                    <a class="mobile-link" href="javascript:void(0);" (click)="openSubscribeModal()">Prenumerera</a>
                  </li> -->
                  <li>
                    <a class="mobile-link" routerLink="/om-oss">Om oss</a>
                  </li>
                  <li>
                    <a class="mobile-link" href="https://fastighetsmedia.se/kontakt" target="_blank">Kontakt</a>
                  </li>
                  <li>
                    <a class="mobile-link" href="{{ loginUrl }}" target="_blank">Logga in</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div class="search-bar-header text-right">
            <div class="search-bar-header-inner d-flex flex-row justify-content-end">
              <div class="input-group mb-3" style="width: 250px">
                <input type="text" [formControl]="searchField" class="form-control search-input" placeholder="Sök nyheter" name="search" (keyup.enter)="search()" autocomplete="off" />
                <div class="input-group-append">
                  <button class="btn btn-brown btn-search" type="button" id="button-addon" style="width: 45px !important" (click)="search()" aria-label="Search">
                    <svg style="width: 18px; height: auto" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
<nav class="navbar navbar-inverse sticky-top" *ngIf="shouldShowHeader">
  <div class="container">
    <ul class="nav navbar-nav main-nav" style="margin-top: -5px">
      <li class="mobile-item-menu" [ngClass]="{ active: isNewsCategory }"><a routerLink="/">START</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/hitta-leverantor">HITTA LEVERANTÖR</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/ekonomi-och-juridik">EKONOMI & JURIDIK</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/produktnyheter">PRODUKTNYHETER</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/reportage">REPORTAGE</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/kundnyheter">KUNDNYHETER</a></li>
      <li class="hidden-xs" routerLinkActive="active"><a routerLink="/las-brf-mappen">LÄS BRF-MAPPEN</a></li>
    </ul>
    <ul class="nav navbar-nav navbar-right show-on-mobile">
      <li class="dropdown">
        <a class="dropdown-toggle no-dropdown-icon" id="dropdownMainMenu" data-bs-toggle="dropdown">
          <img class="hidden-sm hidden-md hidden-lg mobile-menu-bar" src="https://cdn.brfmappen.se/public/images/mobile-menu.svg" alt="Open menu" />
          <img class="hidden-xs hidden-sm hidden-md hidden-lg mobile-menu-close" src="https://cdn.brfmappen.se/public/images/mobile-menu-cross.svg" alt="Close menu" />
        </a>
        <ul class="dropdown-menu" aria-labelledby="dropdownMainMenu">
          <li><a routerLink="/hitta-leverantor">HITTA LEVERANTÖR</a></li>
          <li><a routerLink="/ekonomi-och-juridik">EKONOMI & JURIDIK</a></li>
          <li><a routerLink="/produktnyheter">PRODUKTNYHETER</a></li>
          <li><a routerLink="/reportage">REPORTAGE</a></li>
          <li><a routerLink="/kundnyheter">KUNDNYHETER</a></li>
          <li><a routerLink="/las-brf-mappen">LÄS BRF-MAPPEN</a></li>
          <li><hr /></li>
          <li><a href="https://fastighetsmedia.se/annonsera" target="_blank" class="small">Annonsera</a></li>
          <li><span (click)="openSubscribeModal()" class="small" style="color: #fff">Prenumerera</span></li>
          <li>
            <a class="small" routerLink="/om-oss">Om oss</a>
          </li>
          <li><a href="https://fastighetsmedia.se/kontakt" target="_blank" class="small">Kontakt</a></li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
