import { Injectable } from '@angular/core';
import { BrowserService } from '../browser.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class SvvGoogleAnalyticsService {
  constructor(private $gaService: GoogleAnalyticsService, private browService: BrowserService, private ccService: NgcCookieConsentService) {}

  pageView(url: string, title: string) {
    if (this.browService.isbrowser) {
      if (window['isFirstLoad']) {
        //GA lib already send ping to it's server
        window['isFirstLoad'] = false;
      } else {
        if (this.browService.isbrowser && this.ccService.hasConsented()) {
          this.$gaService.pageView(url, title);
        }
      }
    }
  }
}
