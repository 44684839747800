export const environment = {
  name: 'prod',
  production: true,
  api: 'https://beta-backend.brfmappen.se/api',
  seoTags: {
    default: 'BRF-Mappen',
    homeTitle: 'Hem',
    homeDescription: 'Hjärtstartare i HSB brf Backa redo att rädda liv dygnet runt. Nyheter Varje år drabbas ungefär 6 000 personer av plötsligt hjärtstopp utanför sjukhus.'
  },
  domain: 'https://beta.brfmappen.se',
  version: '1.0.0.204',
  GoogleAnalyticsCode: 'G-6FHD2L0NW7',
  fbPixelId: '635212251548033',
  batchCounter: 10,
  batchCounterMobile: 12,
  reCaptchaSiteKey: '6LfRyNUgAAAAAEmmiCDonvIl0C5s-zFbRkFYPakN',
  brfDashboard: 'https://beta-dashboard.brfmappen.se',
  cookieConfig: { prefix: 'brfdashboard', suffix: 'qa' }
};
