import { Component, Input, OnInit } from '@angular/core';
import { Unsubscriber } from '../../../core/unsubscriber/unsubscriber';
import { SupplierSearchService } from '../../../shared/services/supplier-search.service';
import { ISuppliersResult } from '../../../shared/models/supplier-search/supplier-result.model';
import { ISupplierFilterParam } from '../../models/supplier-search/supplier-filters.model';

@Component({
    selector: 'brf-counter-brf',
    templateUrl: './counter-brf.component.html',
    styleUrls: ['./counter-brf.component.scss']
})
export class CounterBrfComponent extends Unsubscriber implements OnInit {
    startValue1: number = 0;
    endValue1: number = 0;
    startValue2: number = 22000;
    endValue2: number = 30043 ;
    duration: number = 2000; // default 2 seconds
    totalItem: number = 1929;
    result: ISuppliersResult;

    emptyParams: ISupplierFilterParam = {
        categoryIds: [],
        queryString: '',
        regionId: null,
        employeeId: null
      };

    currentCount1: number = 0;
    currentCount2: number = 0;
    constructor(
        private supplierSearchService: SupplierSearchService,        
    ) {
        super();
    }

    ngOnInit(): void {
            this.addSubscription(
            this.supplierSearchService.search(1, 1, this.emptyParams).subscribe((result: ISuppliersResult) => {
                this.result = result;
                this.totalItem = result.total;
                this.endValue1 = this.totalItem;
                this.animateValue('count1', this.startValue1, this.endValue1, this.duration);
                this.animateValue('count2', this.startValue2, this.endValue2, this.duration);
            })
        );

    }

    animateValue(counterName: string, start: number, end: number, duration: number) {
        let range = end - start;
        let current = start;
        let increment = range / (duration / 20); // Increment based on the duration
        let timer = setInterval(() => {
            current += increment;
            if (current >= end) {
                clearInterval(timer);
                current = end;
            }
            if (counterName === 'count1') {
                this.currentCount1 = Math.floor(current);
            } else if (counterName === 'count2') {
                this.currentCount2 = Math.floor(current);
            }
        }, 20);
    }

    formatNumber(value: number): string {
        return new Intl.NumberFormat('sv-SE').format(value);
      }
}
