import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { BrowserService } from '../../../core/browser.service';
import { SeoService } from '../../../core/seo.service';
import { INewsListItem } from '../../../shared/models/news.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SvvGoogleAnalyticsService } from '../../../core/services/gg-analytics.service';
import { EnumExposureLogFormatType, IExposure, IExposureLog } from '../../../shared/models/exposure.model';
import { ExposureService } from '../../../shared/services/exposure.service';
import { Unsubscriber } from '../../../core/unsubscriber/unsubscriber';

@Component({
  selector: 'brf-news-search-tag-name',
  templateUrl: './search-tag-name.component.html',
  styleUrls: ['./search-tag-name.component.scss']
})
export class SearchNewsByTagNameComponent extends Unsubscriber implements OnInit {
  exposures300x250: IExposure[];
  mostReadNews: INewsListItem[] = [];
  latestNews: INewsListItem[] = [];
  mobileView = false;

  constructor(
    private browserService: BrowserService,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private exposureService: ExposureService,
    private sanitizer: DomSanitizer,
    private seo: SeoService,
    private ggAnalyticsService: SvvGoogleAnalyticsService
  ) {
    super();
    if (this.browserService.isbrowser) {
      this.browserService.isMobileScreenSubject.subscribe(flag => (this.mobileView = flag));
      //this.mobileView = window.screen?.width <= 777;
    }
  }

  ngOnInit(): void {
    if (this.browserService.isbrowser) {
      this.fetchExposures();
      this.addSubscription(
        this.activatedRoute.paramMap.subscribe(params => {
          let tagName = params.get('tagname');

          // const obj = { tagName: tagName, tagId: null };
          // this.router.navigateByUrl('/search', { state: obj });
          let newUrl = '/search?tagname=' + tagName;
          location.replace(location.origin + newUrl);
        })
      );
      this.handleSeo();
    }
  }

  fetchExposures() {
    this.exposures300x250 = [];
    this.addSubscription(
      this.exposureService.getExposures('300x250').subscribe((mainList: IExposure[]) => {
        if (mainList?.length > 0) this.setupExposure300x250(mainList);
      })
    );
  }

  setupExposure300x250(fromList: IExposure[], maxCount = 2) {
    var list = fromList.slice(0, maxCount);
    list.forEach(item => {
      item.safeDesktopHTML5Content = this.sanitizer.bypassSecurityTrustHtml(item?.desktopHTML5Content);
      item.safeMobileHTML5Content = this.sanitizer.bypassSecurityTrustHtml(item?.mobileHTML5Content);
    });
    this.exposures300x250 = list;
    this.exposureViewed(this.exposures300x250);
  }

  exposureClicked(exposure: IExposure) {
    if (exposure?.zBase32Id) {
      const payload: IExposureLog[] = [];
      const clickUrlExposure = this.mobileView ? exposure?.clickUrlMobile : exposure?.clickUrl;
      let format: EnumExposureLogFormatType;
      if (exposure.format == '980x180') format = EnumExposureLogFormatType.Exposure980x180;
      else if (exposure.format == '670x300') format = EnumExposureLogFormatType.Exposure670x300;
      else if (exposure.format == '300x250') format = EnumExposureLogFormatType.Exposure300x250;

      payload.push({
        formatType: format,
        id: exposure.zBase32Id,
        clickUrl: clickUrlExposure
      });
      this.addSubscription(
        this.exposureService.postExposureClickLog(payload).subscribe(x => {
          if (this.browserService.isbrowser) {
            setTimeout(() => {
              window.open(clickUrlExposure, '_blank');
            });
          }
        })
      );
    }
  }

  exposureViewed(exposures: IExposure[]) {
    if (exposures?.length > 0) {
      const payload: IExposureLog[] = [];

      exposures.forEach(elem => {
        let format: EnumExposureLogFormatType;
        if (elem.format == '980x180') format = EnumExposureLogFormatType.Exposure980x180;
        else if (elem.format == '670x300') format = EnumExposureLogFormatType.Exposure670x300;
        else if (elem.format == '300x250') format = EnumExposureLogFormatType.Exposure300x250;

        payload.push({
          formatType: format,
          id: elem.zBase32Id,
          clickUrl: this.mobileView ? elem?.clickUrlMobile : elem?.clickUrl
        });
      });
      this.addSubscription(this.exposureService.postExposureViewLog(payload).subscribe());
    }
  }

  handleSeo = () => {
    let searchedText = 'Search text';
    this.titleService.setTitle(searchedText + '| BRF-Mappen');
    this.seo.generateTags({
      title: 'Sök',
      description: searchedText,
      image: 'https://cdn.brfmappen.se/public/images/brf_news_social_logo.webp',
      slug: 'redirect',
      prerenderStatusCode: '301'
    });
    this.ggAnalyticsService.pageView(this.router.url, 'BRF-Mappen');
  };
}
